import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse, Scalars } from '@types';
import ApiTags from '@constants/apiTags';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubjectsAndLevel: builder.query<
      HTTPResponse<any>,
      { query?: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.SUBJECT_LEVEL}${reqBody.query}`,
        method: 'GET'
      })
    }),
    getAllSkillWithTopic: builder.query<
      HTTPResponse<any>,
      { query: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.SKILL_WITH_TOPIC}${reqBody.query}`,
        method: 'GET'
      })
    }),
    createNewAssignment: builder.mutation<HTTPResponse<any>, { body: any }>({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.CREATE_NEW_ASSIGNMENT,
        method: 'POST',
        body: req.body
      })
    }),
    getQuestionRecommendations: builder.mutation<
      HTTPResponse<any>,
      { body: any }
    >({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.QUESTION_RECOMMENDATION,
        method: 'POST',
        body: req.body
      })
    }),
    getClassesForTeacher: builder.query<
      HTTPResponse<any>,
      { param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.CLASSES_FOR_TEACHER}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getClassesForTeachersDropdown: builder.query<
      HTTPResponse<any>,
      { param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.CLASSES_FOR_TEACHER_DROPDOWN}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getQuestionMappings: builder.mutation<HTTPResponse<any>, { body: any }>({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.QUESTION_MAPPING,
        method: 'PUT',
        body: req.body
      })
    }),
    shareHomework: builder.mutation<HTTPResponse<any>, { body: any }>({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.SHARE,
        method: 'POST',
        body: req.body
      })
    }),
    getQuestionDetails: builder.query<
      HTTPResponse<any>,
      { pathVariable: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.QUESTION_DETAILS}/${reqBody.pathVariable}`,
        method: 'GET'
      })
    }),
    getChangeQuestionRecommendation: builder.query<
      HTTPResponse<any>,
      { pathVariable: Scalars['QueryString']; query: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.ASSIGNMENT_CHANGE_QUESTION_RECOMMENDATION}/${reqBody.pathVariable}${reqBody.query}`,
        method: 'GET'
      })
    }),
    getReplaceQuestionList: builder.query<
      HTTPResponse<any>,
      { pathVariable: Scalars['QueryString']; param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.REPLACE_QUESTION}/${reqBody.pathVariable}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getHomeworkList: builder.query<
      HTTPResponse<any>,
      { param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.ASSIGNMENT_LIST}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getHomeworkSummary: builder.query<
      HTTPResponse<any>,
      { param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.SUMMARY}${reqBody.param}`,
        method: 'GET'
      })
    }),
    getStudentsSubmissionDetails: builder.query<
      HTTPResponse<any>,
      { assignmentID: string; param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.STUDENTS_SUMMARY(reqBody.assignmentID)}${
          reqBody.param
        }`,
        method: 'GET'
      })
    }),
    getAssignmentSummary: builder.query<
      HTTPResponse<any>,
      { assignmentID: string; param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.ASSIGNMENT_SUMMARY(reqBody.assignmentID)}${
          reqBody.param
        }`,
        method: 'GET'
      })
    }),
    getAssignmentDetails: builder.query<
      HTTPResponse<any>,
      { assignmentID: string }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.ASSIGNMENT_DETAILS(reqBody.assignmentID)}`,
        method: 'GET'
      })
    }),
    getStudentHomeworkSummary: builder.query<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.STUDENT_HOMEWORK_SUMMARY(
          reqBody.assignmentId,
          reqBody.studentId
        )}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.STUDENT_HOMEWORK_SUMMARY]
    }),
    getStudentResponse: builder.query<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.STUDENT_RESPONSE(
          reqBody.assignmentId,
          reqBody.studentId,
          reqBody.questionId
        )}`,
        method: 'GET'
      })
    }),
    getReports: builder.query<HTTPResponse<any>, any>({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.REPORTS(reqBody.assignmentId)}${
          reqBody.param
        }`,
        method: 'GET'
      })
    }),
    updateStudentReview: builder.mutation<
      HTTPResponse<any>,
      { body: any; reqBody: any }
    >({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.STUDENT_REVIEW(
          req.reqBody.assignmentId,
          req.reqBody.studentId,
          req.reqBody.questionId
        ),
        method: 'PUT',
        body: req.body
      }),
      invalidatesTags: [ApiTags.STUDENT_HOMEWORK_SUMMARY]
    }),
    getStudentsFeedbackDetails: builder.query<
      HTTPResponse<any>,
      { assignmentID: string; param: Scalars['QueryString'] }
    >({
      query: (reqBody) => ({
        url: `${END_POINTS.HOMEWORKS.STUDENT_FEEDBACK_LIST(
          reqBody.assignmentID
        )}${reqBody.param}`,
        method: 'GET'
      })
    }),
    studentFeedbackUnpublish: builder.mutation<
      HTTPResponse<any>,
      { body: any }
    >({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.STUDENT_FEEDBACK_UNPUBLISH,
        method: 'POST',
        body: req.body
      })
    }),
    studentFeedbackUpdateAndPublish: builder.mutation<
      HTTPResponse<any>,
      { body: any; studentHomeworkSectionId: string }
    >({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.STUDENT_FEEDBACK_UPDATE_AND_PUBLISH(
          req.studentHomeworkSectionId
        ),
        method: 'PUT',
        body: req.body
      })
    }),
    studentFeedbackPublish: builder.mutation<HTTPResponse<any>, { body: any }>({
      query: (req) => ({
        url: END_POINTS.HOMEWORKS.STUDENT_FEEDBACK_PUBLISH,
        method: 'POST',
        body: req.body
      })
    })
  })
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetSubjectsAndLevelQuery,
  useLazyGetAllSkillWithTopicQuery,
  useCreateNewAssignmentMutation,
  useGetQuestionRecommendationsMutation,
  useGetClassesForTeacherQuery,
  useGetClassesForTeachersDropdownQuery,
  useLazyGetClassesForTeacherQuery,
  useLazyGetClassesForTeachersDropdownQuery,
  useGetQuestionMappingsMutation,
  useShareHomeworkMutation,
  useLazyGetQuestionDetailsQuery,
  useLazyGetChangeQuestionRecommendationQuery,
  useLazyGetReplaceQuestionListQuery,
  useLazyGetHomeworkListQuery,
  useGetHomeworkSummaryQuery,
  useLazyGetStudentsSubmissionDetailsQuery,
  useGetAssignmentSummaryQuery,
  useGetAssignmentDetailsQuery,
  useGetStudentHomeworkSummaryQuery,
  useLazyGetStudentHomeworkSummaryQuery,
  useGetStudentResponseQuery,
  useLazyGetStudentResponseQuery,
  useLazyGetReportsQuery,
  useUpdateStudentReviewMutation,
  useLazyGetStudentsFeedbackDetailsQuery,
  useStudentFeedbackUnpublishMutation,
  useStudentFeedbackUpdateAndPublishMutation,
  useStudentFeedbackPublishMutation
} = extendedBaseQuery;
