import { MutableRefObject, ReactNode, useRef } from 'react';
import clsx from 'clsx';

import { Button, Icon } from '@components';
import { ButtonProps } from '@types';

interface ModalProps {
  onCancel?: () => void;
  isOpen: boolean;
  className?: string;
  children: ReactNode;
  isCloseIconRequired?: boolean;
  modalHeader?: string;
  headerClassName?: string;
  modalBodyClassName?: string;
  isPrimaryButtonRequired?: boolean;
  isSecondaryButtonRequired?: boolean;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
}

const Modal = ({
  onCancel,
  isOpen,
  className = '',
  children,
  modalHeader = '',
  headerClassName,
  modalBodyClassName,
  isCloseIconRequired = false,
  primaryButtonProps,
  secondaryButtonProps,
  isPrimaryButtonRequired = false,
  isSecondaryButtonRequired = false,
  ...props
}: ModalProps) => {
  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <div
      className={` custom-scroll-bar fixed left-0 top-0 z-50 flex size-full items-start justify-center transition-opacity duration-300 ease-in-out ${
        isOpen ? 'opacity-100' : 'hidden opacity-0'
      }`}
      {...props}
    >
      {/* Modal Overlay */}
      <div className="absolute size-full bg-black/40 opacity-30" />

      {/* Modal Main Content */}
      <div
        className={`relative mx-4 my-auto rounded-3xl bg-primaryLite shadow-lg sm:m-auto ${className}`}
        ref={modalRef}
      >
        {modalHeader && isCloseIconRequired && (
          <div
            className={clsx(
              'flex items-center overflow-hidden',
              modalHeader ? 'justify-between' : 'justify-end'
            )}
          >
            {modalHeader && (
              <h1 className={headerClassName || ''}>{modalHeader}</h1>
            )}
            {isCloseIconRequired && (
              <Button variant="transparent" handleClick={onCancel}>
                <Icon name="close" />
              </Button>
            )}
          </div>
        )}
        {isCloseIconRequired && !modalHeader && (
          <Button
            variant="transparent"
            handleClick={onCancel}
            className="absolute right-0 top-4 mr-2"
          >
            <Icon name="close" />
          </Button>
        )}
        <div className={`${modalBodyClassName || ''}`}>{children}</div>
        {(isSecondaryButtonRequired || isPrimaryButtonRequired) && (
          <div className="flex items-center justify-end gap-2">
            {isSecondaryButtonRequired && (
              <Button variant="secondary" {...secondaryButtonProps} />
            )}
            {isPrimaryButtonRequired && (
              <Button variant="primary" {...primaryButtonProps} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
