import { useMemo } from 'react';
import clsx from 'clsx';

import GreenTick from '@assets/icons/greenTick.svg';
import InCorrect from '@assets/icons/incorrect.svg';
import WarningTick from '@assets/icons/warningTick.svg';
import NotStarted from '@assets/icons/notStarted.svg';
import { AnswerStatus } from '@constants/answer';
import { AnswerStatusProps } from '@types';

const AnswerStatusChip = (props: AnswerStatusProps) => {
  const { status, className } = props;
  const chipStyles = useMemo(() => {
    switch (status) {
      case AnswerStatus.IN_CORRECT:
        return {
          textColor: 'text-error',
          label: 'Incorrect',
          icon: InCorrect
        };
      case AnswerStatus.PARTIALLY_CORRECT:
        return {
          textColor: 'text-warning500',
          label: 'Partially correct',
          icon: WarningTick
        };
      case AnswerStatus.NOT_STARTED:
        return {
          textColor: 'text-error',
          label: 'Not started',
          icon: NotStarted
        };
      case AnswerStatus.CORRECT:
      default:
        return {
          textColor: 'text-green',
          label: 'Correct',
          icon: GreenTick
        };
    }
  }, [status]);

  const { textColor, icon } = chipStyles;

  return (
    <div className={clsx(`flex items-center gap-2 ${textColor} `, className)}>
      <img src={icon} alt="icon" className="mt-0.5 size-5" />
      <div>{chipStyles.label}</div>
    </div>
  );
};

export default AnswerStatusChip;
