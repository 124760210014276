import { FC, memo, useEffect } from 'react';
import { useLocation, matchRoutes } from 'react-router-dom';

import MainLayout from '@layouts/main-layout/MainLayout';
import { SirenProvider } from '@sirenapp/react-inbox';
import { getLocalStorageItem } from '@utils/storage';
import { PageNames, pathList } from '@core/analytics/events';
import { trackPageView } from '@core/analytics';

const MainLayoutWrapper: FC<any> = () => {
  const { pathname } = useLocation();
  const config = {
    recipientId: getLocalStorageItem('siren_inapp_recipientId') || '',
    userToken: getLocalStorageItem('siren_inapp_token') || ''
  };

  useEffect(() => {
    const userMatch = matchRoutes(pathList, pathname);

    if (userMatch && userMatch?.length) {
      trackPageView(PageNames[userMatch[0].route.path]);
    }
  }, [pathname]);

  return (
    <SirenProvider config={config}>
      <MainLayout />
    </SirenProvider>
  );
};

export default memo(MainLayoutWrapper);
