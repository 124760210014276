import React, { FC } from 'react';
import clsx from 'clsx';

import { ErrorCardProps } from '@types';

import Button from '../button/Button';

const ErrorCard: FC<ErrorCardProps> = (props) => {
  const {
    heading,
    subHeading,
    content,
    handleHomeButtonClick,
    className = '',
    buttonLabel = 'Take me home',
    isButtonDisabled = false
  } = props;

  return (
    <div
      className={clsx(
        'shadow-errorCard rounded-48px mx-4 flex flex-col-reverse items-center justify-center bg-white lg:flex-row lg:justify-between xl:mx-auto xl:min-w-1080',
        className
      )}
    >
      <div className="min-w-full whitespace-break-spaces px-48 py-28 text-center lg:min-w-1080 lg:max-w-1080 mobile:px-6 mobile:py-10 tablet:px-16">
        <h1 className="mb-20 text-180 font-bold leading-153 text-grey3 mobile:mb-10 mobile:text-[150px]">
          {heading}
        </h1>
        <h2 className="leading-72 text-lightBlack mb-4 text-4xl font-semibold lg:text-6xl">
          {subHeading}
        </h2>
        <p className="text-lightBlack80 mb-8 text-sm">{content}</p>
        {!isButtonDisabled && (
          <Button
            label={buttonLabel}
            handleClick={handleHomeButtonClick}
            className="mx-auto min-h-14 rounded-full lg:min-w-280"
          />
        )}
      </div>
    </div>
  );
};

export default ErrorCard;
