import { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import { DropdownOption, MultiSelectDropdownProps } from 'types/dropdown';
import useOnClickOutside from '@hooks/clickOutside';

import Icon from '../icon/Icon';
import SearchBar from '../searchbar/SearchBar';
import Checkbox from '../checkbox/Checkbox';
import PlusNChipList from '../plus-n-chip-list/PlusNChipList';
import MultiValueDropDownLabel from '../multi-value-drop-label/MultiValueDropDownLabel';

const MultiSelectDropdown = ({
  label,
  searchBarPlaceholder,
  options,
  selectedOptions,
  onChange,
  className,
  displayChipsCount = 2,
  plusMoreClickRequired = true,
  isLoading,
  isMultiLabeled = false
}: MultiSelectDropdownProps) => {
  const ref = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const findIndex = (item: DropdownOption) =>
    selectedOptions.findIndex((option) => option.id === item.id);

  const filteredOptions: DropdownOption[] = searchValue
    ? options.filter((option: DropdownOption) =>
        option.label?.toLowerCase().includes(searchValue.toLowerCase())
      )
    : options;

  const isSelectAllChecked = filteredOptions?.every((filteredItem) =>
    selectedOptions.find((selectedItem) => filteredItem.id === selectedItem.id)
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectAllCheckedState = event.target.checked;
    let updatedOptions: DropdownOption[] = [];
    if (selectAllCheckedState) {
      const temp = filteredOptions.filter((item) => findIndex(item) < 0);
      updatedOptions.push(...temp);
      onChange(selectedOptions.concat(updatedOptions));
    } else {
      updatedOptions = selectedOptions.filter(
        (selectedItem) =>
          !filteredOptions.find(
            (filteredItem) => selectedItem.id === filteredItem.id
          )
      );
      onChange(updatedOptions);
    }
  };

  const handleOnChange = (
    element: DropdownOption,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedState = event.target.checked;
    let updatedOptions: DropdownOption[];
    const index = findIndex(element);
    if (checkedState) {
      if (index < 0) {
        updatedOptions = [...selectedOptions, element];
        onChange(updatedOptions);
      }
    } else if (index >= 0) {
      updatedOptions = selectedOptions.filter(
        (option) => option.id !== element.id
      );
      onChange(updatedOptions);
    }
  };

  const handleOnRemove = (item: DropdownOption) => {
    const index = findIndex(item);
    let updatedOptions: DropdownOption[];
    if (index >= 0) {
      updatedOptions = selectedOptions.filter(
        (option) => option.id !== item.id
      );
      onChange(updatedOptions);
    }
  };

  const renderDropdownLabel = () => {
    const onClickPlusMoreText = () => setIsDropdownOpen(false);
    return (
      <>
        <span className="text-base text-grey40">
          {!selectedOptions.length && label}
        </span>
        <PlusNChipList
          selectedOptions={selectedOptions}
          handleOnRemove={handleOnRemove}
          plusMoreClickRequired={plusMoreClickRequired}
          displayChipsCount={displayChipsCount}
          chipClassName="bg-primary text-theme"
          tooltipChipClassName="bg-primary text-theme"
          onClickPlusMoreText={onClickPlusMoreText}
        />
      </>
    );
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setSearchValue('');
  };

  const handleSearchBarChange = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  useOnClickOutside(ref, () => {
    setIsDropdownOpen(false);
    setSearchValue('');
  });

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div ref={ref} className={`relative w-full ${className || ''}`}>
      <div
        role="presentation"
        onClick={handleDropdownClick}
        className="relative flex cursor-pointer items-center rounded-xl border border-grey40 p-2 hover:border-primary desktop:px-4 desktop:py-2"
      >
        {renderDropdownLabel()}
        <Icon
          name="up"
          className={`absolute right-4 ${isDropdownOpen ? '' : 'rotate-180'}`}
        />
      </div>
      {isDropdownOpen && (
        <div className="absolute right-0 z-10 mt-1 h-200 w-full overflow-auto rounded-xl border border-grey40 bg-white p-4 shadow-sm">
          <SearchBar
            placeholder={searchBarPlaceholder}
            onSearch={handleSearchBarChange}
            value={searchValue}
            searchInputStyle="pr-50"
          />
          {filteredOptions.length > 0 ? (
            <div className="mt-3 rounded bg-white py-2 shadow-sm">
              <div className="border-b border-b-grey30 py-2">
                <Checkbox
                  handleChange={handleSelectAll}
                  label="Select All"
                  labelStyle="text-base !text-content !font-medium"
                  checked={isSelectAllChecked}
                />
              </div>
              <div className="custom-scroll max-h-180 overflow-y-auto">
                {filteredOptions.map((item, key) => (
                  <Checkbox
                    label={
                      isMultiLabeled &&
                      item?.multiLabels &&
                      item?.multiLabels ? (
                        <MultiValueDropDownLabel
                          label={item?.multiLabels.label}
                          subLabel={item?.multiLabels.subLabel}
                        />
                      ) : (
                        item.label
                      )
                    }
                    key={item.value}
                    handleChange={(e) => handleOnChange(item, e)}
                    checked={findIndex(item) >= 0}
                    className="py-2"
                    labelStyle="!text-content text-base font-normal"
                    htmlKey={String(key)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="shadow-multiSelectDropdownShadow mt-3 flex h-14 items-center p-4 text-base font-normal italic text-content">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
