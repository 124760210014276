import mixpanel from 'mixpanel-browser';

import Event from './events';

// To initialize the mixpanel instance
export const initMixpanel = () => {
  mixpanel.init(process.env.REACT_APP_PUBLIC_MIXPANEL_TOKEN ?? '', {
    ignore_dnt: true
  });
};

// To track mixpanel event
export const sendMixpanelEvent = (
  event: string,
  data?: Record<string, unknown>
) => {
  mixpanel.track(event, data);
};

export const trackPageView = (pageName: string, data = {}) => {
  mixpanel.track_pageview({ page: pageName, ...data });
};

// persistent sets of properties that describe a user
export const setUserProperties = (
  identity: string,
  userProperties: Record<string, unknown>
) => {
  mixpanel.identify(identity);
  Object.entries(userProperties).forEach(([key, value]) => {
    mixpanel.people.set(key, value);
  });
};

export { Event };
