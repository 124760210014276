export const sizeStyle = {
  small: 'h-11',
  medium: 'h-12.5',
  big: 'h-14'
};

export const inputStyles = {
  common:
    'peer p-2 w-full border border-grey40 rounded-lg hover:border-grey focus:outline-none focus:border-primary5 focus-within:border-primary5 focus:shadow-inputShadow',
  readonly: '!border-grey40',
  error: '!border-2 !border-error'
};

export const labelStyles = {
  common:
    'absolute cursor-text left-0 -translate-y-6 text-sm text-content font-medium'
};
