import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentAssignmentInfo: {},
  topicAndSkillList: {},
  classDetails: [],
  selectedSubject: {},
  selectedTopicList: [],
  subjectList: []
};

export const homeworkSlice = createSlice({
  name: 'homework',
  initialState,
  reducers: {
    updateHomeworkInfo: (state, action: PayloadAction<any>) => {
      state.currentAssignmentInfo = action.payload;
    },
    updateTopicAndSkills: (state, action: PayloadAction<any>) => {
      state.topicAndSkillList = action.payload;
    },
    updateClassDetails: (state, action: PayloadAction<any>) => {
      state.classDetails = action.payload;
    },
    updateSelectedSubject: (state, action: PayloadAction<any>) => {
      state.selectedSubject = action.payload;
    },
    updateSelectedTopicList: (state, action: PayloadAction<any>) => {
      state.selectedTopicList = action.payload;
    },
    updateSubjectList: (state, action: PayloadAction<any>) => {
      state.subjectList = action.payload;
    },
    resetHomeworkState: () => initialState
  }
});

export const {
  updateHomeworkInfo,
  resetHomeworkState,
  updateTopicAndSkills,
  updateClassDetails,
  updateSelectedSubject,
  updateSelectedTopicList,
  updateSubjectList
} = homeworkSlice.actions;
export default homeworkSlice.reducer;
