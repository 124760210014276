import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { PieChartProps, PieChartType } from '@types';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  label,
  value,
  colors,
  showDataLabel = true,
  chartType = PieChartType.PIE // Add a new prop to determine chart type
}: PieChartProps) => {
  const data = {
    labels: label,
    datasets: [
      {
        data: value,
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 10
      }
    ]
  };

  const options = {
    layout: {
      padding: 5
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: chartType === PieChartType.DONUT ? '50%' : '0%', // Adjust cutout for donut chart
    plugins: {
      legend: {
        display: false // Hide the legend labels
      },
      tooltip: {
        color: 'black',
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        padding: 10,
        borderWidth: 0.5,
        borderColor: 'black',
        callbacks: {
          label(context: any) {
            return `${context.label}: ${context.raw}`;
          }
        }
      },
      datalabels: {
        display: showDataLabel,
        anchor: 'center' as const,
        align: 'bottom' as const,
        textAlign: 'center' as const,
        font: {
          size: 20
        },
        labels: {
          value: {
            color: 'black'
          }
        },
        formatter(val: any) {
          return `${val}`;
        }
      }
    }
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
