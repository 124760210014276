import { Icon } from '@components';
import { AnswerStatus } from '@constants/answer';
import { ResultCardProps } from '@types';

const ResultCard = ({ type }: ResultCardProps) => {
  const iconName = {
    [AnswerStatus.CORRECT]: 'correct',
    [AnswerStatus.IN_CORRECT]: 'wrong',
    [AnswerStatus.PARTIALLY_CORRECT]: 'partial',
    [AnswerStatus.NOT_STARTED]: 'partial'
  };

  const wrapperStyle = {
    [AnswerStatus.CORRECT]: 'border border-green  bg-greenLite',
    [AnswerStatus.IN_CORRECT]: 'border border-error bg-errorLite',
    [AnswerStatus.PARTIALLY_CORRECT]: 'border border-warning bg-warning50',
    [AnswerStatus.NOT_STARTED]: 'border border-warning bg-warning50'
  };

  const title = {
    [AnswerStatus.CORRECT]: 'Correct Answer',
    [AnswerStatus.IN_CORRECT]: 'Incorrect answer',
    [AnswerStatus.PARTIALLY_CORRECT]: 'Partially correct answer',
    [AnswerStatus.NOT_STARTED]: 'Not started'
  };

  return (
    <div
      className={`flex items-center justify-center gap-4 rounded-xl p-5 ${wrapperStyle[type]}`}
    >
      <Icon name={iconName[type]} size="24" key={iconName[type]} />
      <div className="text-2xl font-semibold leading-6">{title[type]}</div>
    </div>
  );
};

export default ResultCard;
