import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Mutex } from 'async-mutex';

import { API_URL, END_POINTS } from '@config';
import {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  setTokensInStorage,
  StorageKeys
} from '@utils/storage';
import { ApiError, RefreshTokenResponse, TokenResponse } from '@types';
import useNotify from '@hooks/notify';
import ApiTags from '@constants/apiTags';
import { AUTH } from '@constants/routes';
import { HTTPStatus } from '@constants/apiTypes';

const mutex = new Mutex();

// TODO: might need seperate cases
const handle403And500Error = (errorCode: string) => {
  window.location.replace(
    `/error/${Object.keys(HTTPStatus).includes(errorCode) ? errorCode : '404'}`
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { createErrorAlert } = useNotify();
  createErrorAlert(
    `${errorCode}: Something went wrong. Please try again later`
  );
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers) => {
    const token = getLocalStorageItem(StorageKeys.ACCESS_TOKEN);
    const schoolId = getLocalStorageItem(StorageKeys.SCHOOL_ID);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (schoolId) {
      headers.set('x-school-id', schoolId);
    }
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = getLocalStorageItem(StorageKeys.REFRESH_TOKEN);
        const accessToken = getLocalStorageItem(StorageKeys.ACCESS_TOKEN);
        const refreshResult = await baseQuery(
          {
            url: END_POINTS.AUTH.REFRESH,
            method: 'POST',
            body: { refreshToken, accessToken }
          },
          api,
          extraOptions
        );
        const refreshData = refreshResult as { data: RefreshTokenResponse };
        if (refreshResult.data) {
          setTokensInStorage(refreshData.data.data as TokenResponse);
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          clearAllLocalStorageItems();
          window.location.replace(AUTH.LOGIN);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  } else if (result.error) {
    const error = result.error as ApiError;
    const errorCode = error.data?.statusCode;
    if (
      (error.status === 403 || error.status === 404) &&
      errorCode &&
      !args?.url.includes('auth')
    ) {
      handle403And500Error(errorCode);
      // TODO: might need sepeate cases in future
    } else if (error.status === 500 && errorCode) {
      handle403And500Error(errorCode);
    }
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
  tagTypes: ['Branches', ApiTags.STUDENT_HOMEWORK_SUMMARY]
});
