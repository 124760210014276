import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@core/store';

const initialState = {
  alerts: [] as AlertType[]
};

type AlertType = { message: any; type: any };

export type NotificationState = {
  alerts: AlertType[];
};
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    createAlert: (
      state,
      { payload: { alert } }: PayloadAction<{ alert: AlertType }>
    ) => ({
      ...state,
      alerts: state.alerts.concat(alert as AlertType)
    })
  }
});

export const { createAlert } = notificationSlice.actions;
export default notificationSlice.reducer;
export const selectAlerts = (state: RootState) => state.notification.alerts;
