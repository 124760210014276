import React from 'react';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ReactComponent as SortUp } from '@assets/icons/sortUp.svg';
import { ReactComponent as SortDown } from '@assets/icons/sortDown.svg';
import { Column, SortOrder, TableProps } from '@types';

const CustomTable: React.FC<TableProps> = ({
  columns,
  data,
  isRowClickRequired = false,
  rowDataStyle,
  headerDataStyle,
  selectedRow,
  handleSelect,
  onSortClick,
  setLastRow,
  className,
  showNoResults = false,
  noResultText,
  sortOrder,
  sortBy,
  tableHeaderCellStyle
}) => {
  const isRowSelected = (rowId: string) => {
    if (selectedRow === rowId) return true;
    return false;
  };

  const assignLastRowForInfiniteScroll = (
    node: HTMLTableRowElement | null,
    rowId: number
  ) => {
    if (data.length && data[data.length - 1].id === rowId) {
      setLastRow?.(node);
    }
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: `'DM Sans', 'sans-serif'`
      }
    }
  });

  const getTableHeaderCellStyle = (index: number) =>
    index === columns.length - 1 ? '' : tableHeaderCellStyle || '';
  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        className={`table-scroll h-full bg-white ${className || ''}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="h-11 leading-5">
              {columns.map((column: Column, index: number) => (
                <TableCell
                  className={`border-r-3 !bg-primary !font-medium !text-theme ${
                    headerDataStyle || ''
                  }`}
                  key={column.accessor}
                  size="small"
                >
                  <div
                    className={`flex justify-between gap-2 ${getTableHeaderCellStyle(
                      index
                    )}`}
                  >
                    {column.Header}
                    {column.sort && (
                      <div className="flex flex-col items-center justify-center">
                        <SortUp
                          className={`size-3 cursor-pointer ${
                            sortOrder === SortOrder.ASC &&
                            sortBy === column.accessor &&
                            'stroke-2'
                          } `}
                          onClick={() =>
                            onSortClick &&
                            onSortClick(column.accessor, SortOrder.ASC)
                          }
                        />
                        <SortDown
                          className={`size-3 cursor-pointer ${
                            sortOrder === SortOrder.DESC &&
                            sortBy === column.accessor &&
                            'stroke-2'
                          }`}
                          onClick={() =>
                            onSortClick &&
                            onSortClick(column.accessor, SortOrder.DESC)
                          }
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showNoResults ? (
              <TableRow
                className={`group !border-b !border-b-grey10 bg-white hover:bg-primary1 ${
                  isRowClickRequired ? 'cursor-pointer' : ''
                }`}
              >
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  className="!text-sm font-normal italic !text-grey"
                >
                  {noResultText || 'No data to show'}
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row) => (
                <TableRow
                  key={row.id}
                  ref={(node) => assignLastRowForInfiniteScroll(node, row.id)}
                  selected={isRowClickRequired ? isRowSelected(row.id) : false}
                  onClick={() => handleSelect && handleSelect(row)}
                  className={`group !border-b !border-b-grey10 bg-white hover:bg-primary1 ${
                    isRowClickRequired ? 'cursor-pointer' : ''
                  }
                  ${isRowSelected(row.id) ? `!bg-primary10` : 'inherit'}`}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={`${row.id}_${column.accessor}`}
                      className={clsx(
                        '!text-base',
                        column.style || '',
                        rowDataStyle || ''
                      )}
                      width={column.width || ''}
                    >
                      {row[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default CustomTable;
