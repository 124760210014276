import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ApiError } from '@types';
import useNotify from '@hooks/notify';
import { AUTH } from '@constants/routes';
import { setUserProperties } from '@core/analytics';
import { SideNavBar, Spinner, Topbar } from '@components';
import { setCurrentUser } from '@features/authentication/userSlice';
import { getLocalStorageItem, setLocalStorageItem } from '@utils/storage';
import { useGetUserDetailsQuery } from '@features/authentication/queries/AuthQuery';
import { updateClassDetails } from '@features/homework-management/slices/homeworkSlice';
import { useLazyGetClassesForTeachersDropdownQuery } from '@features/homework-management/queries/HomeworkQuery';

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createErrorAlert } = useNotify();

  const { data, isLoading } = useGetUserDetailsQuery();
  const [getClassDetails] = useLazyGetClassesForTeachersDropdownQuery();

  const token = getLocalStorageItem('token');

  const fetchClassDetails = async () => {
    const response = await getClassDetails({ param: '' });
    if ('data' in response) {
      dispatch(updateClassDetails(response?.data?.data));
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error.data?.message);
    }
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(setCurrentUser({ user: data?.data }));
      setLocalStorageItem('schoolId', data?.data?.schoolIds[0]);
      setUserProperties(data?.data?.id, {
        userId: data?.data?.id,
        name: data?.data?.name,
        email: data?.data?.email
      });
      if (data?.data?.schoolIds[0]) {
        fetchClassDetails();
      }
    }
  }, [data]);

  useEffect(() => {
    if (!token) navigate(AUTH.LOGIN);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="size-full">
        <div className="hidden sm:block">
          <SideNavBar userDetails={data?.data} />
        </div>
        <div className="sm:hidden">
          <Topbar />
        </div>
        <div className="h-[calc(100%-60px)] overflow-y-auto bg-primaryLite sm:h-screen">
          <div className="wrapper" id="main-layout-wrapper">
            <Suspense
              fallback={
                <div className="absolute inset-0 top-1/2 flex size-full -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
