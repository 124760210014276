import { Option, RadioGroupProps } from 'types';

import RadioButton from './RadioButton';

const RadioGroup = ({
  options,
  selectedValue,
  handleChange,
  disabled,
  labelStyle = '',
  orientation = 'horizontal',
  className,
  ...props
}: RadioGroupProps) => {
  const onSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };

  return (
    <div
      className={`flex ${
        orientation === 'horizontal' ? 'flex-row' : 'flex-col'
      } ${className || ''}`}
      {...props}
    >
      {options?.map((option: Option) => (
        <div
          key={option.key}
          className={orientation === 'horizontal' ? 'mr-3.5' : 'mb-3.5'}
        >
          <RadioButton
            isSelected={selectedValue === option.key}
            onChange={onSelection}
            label={option.value}
            value={option.key}
            key={option.key}
            disabled={disabled}
            labelStyle={labelStyle}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
