import { months } from '@constants/date';

/* eslint-disable import/prefer-default-export */
export const formatTime = (time: number): string => {
  // Format seconds as MM:SS
  const minutes = Math.floor(time / 60);
  const remainingSeconds = time % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const getOrdinalSuffix = (currentDateString: String) => {
  const day = Number(currentDateString);
  return day > 0
    ? ['th', 'st', 'nd', 'rd'][
        (day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10
      ]
    : '';
};

export type DateFormat =
  | 'dd-mm-yyyy' // 16-01-2024
  | 'yyyy-mm-dd' // 2024-01-16
  | 'dd-mm-yyyy, hh:mm' // 16-01-2024, 13:01
  | 'DD MMM YYYY' // 16 Jan 2024
  | 'DD MMMM YYYY' // 16 January 2024
  | 'MMM DD'
  | 'hh:mm';

export const formatISODate = ({
  date,
  separator = '/',
  format = 'dd-mm-yyyy',
  startOfTheDay = false,
  endOfTheDay = false,
  inISOString = false,
  inLocalTimeZone = false,
  dateSuffix = false
}: {
  date: string | Date;
  separator?: string;
  format?: DateFormat;
  startOfTheDay?: boolean;
  endOfTheDay?: boolean;
  inISOString?: boolean;
  inLocalTimeZone?: boolean;
  dateSuffix?: boolean;
}) => {
  if (!date) return '';
  let formattedDate = typeof date === 'string' ? new Date(date) : date;
  if (startOfTheDay || endOfTheDay || inISOString) {
    if (startOfTheDay) {
      formattedDate.setHours(0, 0, 0, 0);
    } else if (endOfTheDay) {
      formattedDate.setHours(23, 59, 59, 0);
    }

    return formattedDate.toISOString();
  }

  if (inLocalTimeZone)
    formattedDate = new Date(
      formattedDate.getTime() + formattedDate.getTimezoneOffset() * 60000 - 1
    );

  const year = formattedDate.getFullYear();
  const monthIndex = formattedDate.getMonth();
  const derivedDate = formattedDate.getDate();
  const hours = formattedDate.getHours();
  const minutes =
    formattedDate.getMinutes() < 10
      ? `0${formattedDate.getMinutes()}`
      : formattedDate.getMinutes();
  const currentDate = derivedDate < 10 ? `0${derivedDate}` : `${derivedDate}`;

  switch (format) {
    case 'dd-mm-yyyy': {
      const monthNumber = months[monthIndex].numeral;
      return `${currentDate}${separator}${monthNumber}${separator}${year}`;
    }
    case 'yyyy-mm-dd': {
      const monthNumber = months[monthIndex].numeral;
      return `${year}${separator}${monthNumber}${separator}${currentDate}`;
    }
    case 'dd-mm-yyyy, hh:mm': {
      const monthNumber = months[monthIndex].numeral;
      return `${currentDate}${separator}${monthNumber}${separator}${year}, ${hours}:${minutes}`;
    }
    case 'DD MMM YYYY': {
      const monthShortName = months[monthIndex].shortName;
      return `${currentDate} ${monthShortName} ${year}`;
    }
    case 'DD MMMM YYYY': {
      const monthName = months[monthIndex].name;
      return `${currentDate} ${monthName} ${year}`;
    }
    case 'MMM DD': {
      const monthName = months[monthIndex].shortName;
      return `${monthName} ${currentDate}${
        dateSuffix ? getOrdinalSuffix(currentDate) : ''
      }`;
    }

    case 'hh:mm': {
      return `${hours}:${minutes}`;
    }
    default:
      return '';
  }
};

export const getDay = (date: Date) => date.getDate();

export const getMonthAndYear = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};

export const getDayOfWeek = (date: Date) =>
  date.toLocaleString('default', { weekday: 'long' });

export const getFormattedTime = (date: Date) =>
  date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

export const getFormattedDate = (dateValue?: string) => {
  if (!dateValue) return '';
  const date = new Date(dateValue);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}.${formattedMonth}.${year}`;
};
export const getDateRangeForCurrentYear = () => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
  const endDate = new Date(today.getFullYear(), 11, 31); // December 31st of the current year

  return { startDate, endDate };
};

export const getDateRangeForCurrentMonth = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 30);
  const endDate = today;

  return { startDate, endDate };
};

export const getDateRangeForCurrentWeek = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 7);
  const endDate = today;

  return { startDate, endDate };
};

export const getFormattedDateRange = (startDate: Date, endDate: Date) => {
  const startMonth = startDate.toLocaleDateString('en-US', { month: 'short' });
  const endMonth = endDate.toLocaleDateString('en-US', { month: 'short' });

  const startDateString = `${startDate.getDate()} ${startMonth}`;
  const endDateString = `${endDate.getDate()} ${endMonth}, ${endDate.getFullYear()}`;

  return `${startDateString} - ${endDateString}`;
};

export const getDateRangeForToday = () => {
  const today = new Date();

  return { startDate: today, endDate: today };
};

export const convertDateRangeToQueryParams = (date: {
  startDate: Date;
  endDate: Date;
}) => {
  const startDate = new Date(date.startDate.getTime());
  const endDate = new Date(date.endDate.getTime());

  return {
    fromDate: formatISODate({
      date: startDate,
      startOfTheDay: true,
      inISOString: true,
      inLocalTimeZone: true
    }),
    toDate: formatISODate({
      date: endDate,
      endOfTheDay: true,
      inISOString: true,
      inLocalTimeZone: true
    })
  };
};
