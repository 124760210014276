import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { SETTINGS } from '@constants/routes';

const Settings = lazy(
  () => import('@features/settings/pages/settings/Settings')
);

export const SettingsRoutes: RouteObject[] = [
  {
    path: SETTINGS.SETTINGS,
    element: <Settings />
  }
];

export default SettingsRoutes;
