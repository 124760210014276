import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Spinner from '@components/spinner/Spinner';
import backgroundImage from '@assets/images/auth_background.svg';

const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const AuthLayout = () => (
  <div className="flex h-screen items-center" style={backgroundStyle}>
    <section className="flex w-full items-center justify-center bg-transparent p-4 lg:p-4 xl:p-10 ">
      <div className="rounded-48 flex flex-col items-center justify-center border-borderPrimary3 bg-white p-8 pt-11 md:min-w-568 md:max-w-568 md:p-16 lg:p-90 xl:m-6">
        <Suspense
          fallback={
            <div className="flex size-full items-center justify-center">
              <Spinner />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </section>
  </div>
);

export default AuthLayout;
