import React, { FC } from 'react';

import { PaginationLimitProps } from '@types';

const PaginationLimit: FC<PaginationLimitProps> = (props) => {
  const { description, totalCount, paginationLimit, setPaginationLimit } =
    props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaginationLimit(Number(event.target.value));
  };

  const options = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= totalCount; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className="flex items-center gap-4 mobile:gap-2 mobile:justify-start">
      <label className="text-gray-700 mobile:text-xs">
        <span className="mr-4">{description}</span>
        <select
          value={paginationLimit}
          onChange={handleChange}
          className="custom-scroll-bar rounded-lg border border-r-8 border-transparent bg-transparent p-2 outline outline-1 outline-gray-300"
        >
          {options}
        </select>
      </label>
      <span className="items-center mobile:text-xs">
        <strong>1 - {paginationLimit}</strong> of{' '}
        <strong>{Math.ceil(totalCount / paginationLimit)}</strong>
      </span>
    </div>
  );
};

export default PaginationLimit;
