export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';

export const errorScreenData = {
  '404': {
    heading: '404',
    subHeading: 'Page not found',
    content:
      "It looks like the page you're trying to find doesn't exist. This might be because of a mistyped URL or an out-of-date link. Please check the URL or head back to the homepage."
  },
  '500': {
    heading: '500',
    subHeading: 'Internal server error',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance."
  },
  '401': {
    heading: '401',
    subHeading: 'Oopss, User not authorized to access this content',
    content:
      "It looks like the page you're trying to cannot access by you. This might be because of a mistyped URL or an out-of-date link. Please check the URL or head back to the homepage."
  },
  '503': {
    heading: '503',
    subHeading: 'Oopss, service unavailable',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance."
  },
  [SOMETHING_WENT_WRONG]: {
    heading: 'Oopss!',
    subHeading: 'Something went wrong!, Please try again',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance."
  }
};
