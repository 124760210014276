import { ReactComponent as HomeWorkIcon } from '@assets/icons/homework.svg';
import { ReactComponent as ClassesIcon } from '@assets/icons/classes.svg';
import { ReactComponent as StudentsIcon } from '@assets/icons/students.svg';
import { ReactComponent as NotificationIcon } from '@assets/icons/notifications.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';

import {
  CLASSES,
  HOMEWORK_MANAGEMENT,
  NOTIFICATIONS,
  SETTINGS,
  STUDENTS
} from './routes';

const MENU_ITEMS = [
  {
    id: 2,
    Icon: HomeWorkIcon,
    label: 'Homeworks',
    route: HOMEWORK_MANAGEMENT.HOMEWORKS
  },
  {
    id: 3,
    Icon: NotificationIcon,
    label: 'Notifications',
    route: NOTIFICATIONS.NOTIFICATIONS
  },
  {
    id: 4,
    Icon: ClassesIcon,
    label: 'Classes',
    route: CLASSES.CLASSES
  },
  {
    id: 5,
    Icon: StudentsIcon,
    label: 'Students',
    route: STUDENTS.STUDENTS
  },
  {
    id: 6,
    Icon: SettingsIcon,
    label: 'Settings',
    route: SETTINGS.SETTINGS
  }
];

export default MENU_ITEMS;
