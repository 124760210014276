import { useRef, useState, ChangeEvent, useEffect } from 'react';
import { Skeleton } from '@mui/material';

import { DropdownProps, DropdownOption } from 'types';
import { Icon, MultiValueDropDownLabel } from '@components';
import useOnClickOutside from '@hooks/clickOutside';

import Button from '../button/Button';

const Dropdown = (props: DropdownProps) => {
  const {
    filterByLabel,
    currentFilter,
    filterOptions,
    onSelectFilter,
    className,
    optionsStyle,
    labelStyle,
    isLoading,
    isSearchBarVisible = false,
    placeholder,
    placeholderStyle,
    isError = false,
    errorMessage = '',
    handleClear = () => null,
    isClearFilterButton = false,
    isMultiLabeled = false
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [inputSearchString, setInputSearchString] = useState<string>('');
  const [dropDownOptions, setDropDownOptions] = useState<DropdownOption[]>();

  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onClickFilter = (option: DropdownOption) => {
    if (option.value !== currentFilter?.value && !option.isDisabled) {
      onSelectFilter(option);
    }
    toggleDropdown();
  };

  const selectedFilter: DropdownOption | undefined = filterOptions?.find(
    (option) => option.value === currentFilter?.value
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setInputSearchString(event.target.value as string);
    if (event.target.value === '') {
      setDropDownOptions(filterOptions);
    } else {
      const searchResults = filterOptions?.filter((value: DropdownOption) =>
        value.label.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setDropDownOptions(searchResults);
    }
  };

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setDropDownOptions(filterOptions as DropdownOption[]);
  }, [filterOptions]);

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className={`relative ${className || ''}`} ref={ref}>
      <div className="flex items-center gap-2">
        {filterByLabel && (
          <span className="text-base font-medium text-grey">
            {filterByLabel}
          </span>
        )}
        <Button
          variant="tertiary"
          handleClick={toggleDropdown}
          size="medium"
          label={
            (selectedFilter?.multiLabels?.label &&
              selectedFilter?.multiLabels?.subLabel && (
                <MultiValueDropDownLabel
                  label={selectedFilter?.multiLabels?.label}
                  subLabel={selectedFilter?.multiLabels?.subLabel}
                />
              )) ||
            selectedFilter?.label ||
            placeholder
          }
          rightIconName="down-icon-dark"
          className={`min-w-166 justify-between ${className}`}
          labelStyle={
            placeholder && !selectedFilter?.label
              ? `!text-base text-grey40 ${placeholderStyle}`
              : `!font-normal ${labelStyle || ''}`
          }
          rightIconStyle={isDropdownOpen ? 'rotate-180' : ''}
          isClearFilterButton={isClearFilterButton}
          handleClear={handleClear}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`w-220 absolute right-0 z-10 mt-1 rounded-lg border border-grey40 bg-theme px-3 py-1 shadow-sm ${
            optionsStyle || 'right-0'
          }`}
        >
          {isSearchBarVisible && (
            <div className="flex items-center justify-center">
              <input
                id="search-input"
                type="text"
                value={inputSearchString}
                onChange={(e) => handleSearch(e)}
                placeholder="Search here !"
                autoComplete="off"
                className="m-0 flex w-full appearance-none items-center justify-between border-primary5 bg-transparent p-0 py-2 pr-4 text-sm focus:outline-none"
              />
              <Icon
                name="search-icon"
                size="medium"
                className="cursor-pointer"
                stroke="black"
              />
            </div>
          )}
          {dropDownOptions?.map((option) => (
            <div
              key={option?.id}
              className={`flex  items-center justify-between border-b border-b-grey40 py-2 pr-4 text-sm last:border-b-0 mobile:overflow-hidden ${
                option.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              role="presentation"
              onClick={() => onClickFilter(option)}
            >
              {isMultiLabeled &&
              option?.multiLabels?.label &&
              option?.multiLabels?.subLabel ? (
                <MultiValueDropDownLabel
                  label={option?.multiLabels?.label}
                  subLabel={option?.multiLabels?.subLabel}
                />
              ) : (
                option.label
              )}
            </div>
          ))}
        </div>
      )}
      {isError && (
        <div className="mt-1 flex">
          <Icon name="exclamation" stroke="#eb4034" size="medium" />
          <span className="ml-1 mt-0.5 text-xs text-red-600">
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
