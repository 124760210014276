import { useRef, useState } from 'react';

import { DropdownOption } from 'types/dropdown';
import { PlusNChipListProps } from '@types';
import useOnClickOutside from '@hooks/clickOutside';

import Chip from '../chip/Chip';
import CustomTooltip from '../tooltip/Tooltip';

import './styles.css';

const PlusNChipList = (props: PlusNChipListProps) => {
  const ref = useRef(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const {
    displayChipsCount = 2,
    selectedOptions,
    handleOnRemove,
    isCloseIconRequired = true,
    plusMoreClickRequired = false,
    chipClassName,
    tooltipChipClassName,
    plusNChipBgColor = 'bg-white',
    id,
    tooltipColor = 'white',
    className,
    onClickPlusMoreText
  } = props;

  const displayChipList = selectedOptions.slice(0, displayChipsCount);
  const restOfTheChipList = selectedOptions.slice(displayChipsCount);

  const tooltipLabels = restOfTheChipList.map((option) => option.label);

  const handleChipRemove = (
    e: React.MouseEvent<HTMLElement>,
    chipElement: DropdownOption
  ) => {
    e.stopPropagation();
    if (handleOnRemove) handleOnRemove(chipElement);
  };

  const handlePlusMoreClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
    onClickPlusMoreText?.();
  };

  const renderPlusMoreText = () => (
    <div
      className={`ml-0.5 flex h-7 cursor-pointer items-center rounded-full  px-2 py-0.5 text-xs font-medium text-primary hover:underline ${plusNChipBgColor}`}
    >
      +{selectedOptions.length - displayChipsCount} More
    </div>
  );

  useOnClickOutside(ref, () => {
    setShowTooltip(false);
  });

  return (
    <div className={`flex items-center ${className}`}>
      {displayChipList.map((item) => (
        <>
          <Chip
            label={item.label}
            className={`mr-0.5 ${chipClassName || ''}`}
            onRemove={(e) => handleChipRemove(e, item)}
            key={item.value}
            isCloseIconRequired={isCloseIconRequired}
            tooltipId={`onDisplay-${item.value}`}
          />
          <CustomTooltip
            id={`onDisplay-${item.value}`}
            className="shadow-tooltipShadow custom-scroll-bar z-20 max-w-500 mobile:max-w-300"
            place="bottom"
            variant="dark"
            noArrow={false}
            opacity={0.9}
          />
        </>
      ))}

      {!plusMoreClickRequired
        ? // displays tooltip on hover
          selectedOptions.length > displayChipsCount && (
            <div data-tooltip-id={`plus-more-${id}`}>
              {renderPlusMoreText()}
              <CustomTooltip
                id={`plus-more-${id}`}
                className="!shadow-tooltipShadow !max-h-314 !max-w-400 !overflow-y-auto"
                variant={tooltipColor === 'white' ? 'light' : 'dark'}
              >
                <div className="flex flex-wrap gap-2">
                  {tooltipLabels?.map((item) => (
                    <>
                      <Chip
                        label={item}
                        className="bg-grey40"
                        key={item}
                        isCloseIconRequired={isCloseIconRequired}
                        tooltipId={`PlusMoreNotRequired-${item}`}
                      />
                      <CustomTooltip
                        id={`PlusMoreNotRequired-${item}`}
                        className="shadow-tooltipShadow custom-scroll-bar z-20 max-w-500 mobile:max-w-300"
                        place="bottom"
                        variant="dark"
                        noArrow={false}
                      />
                    </>
                  ))}
                </div>
              </CustomTooltip>
            </div>
          )
        : // displays tooltip on click
          selectedOptions.length > displayChipsCount && (
            <div
              role="presentation"
              onClick={handlePlusMoreClick}
              className="relative"
              ref={ref}
              data-tooltip-id={`plus-more-click-${id}`}
            >
              {renderPlusMoreText()}
              <CustomTooltip
                id={`plus-more-click-${id}`}
                className="shadow-tooltipShadow custom-scroll-bar z-20 max-w-500 mobile:max-w-300"
                place="bottom"
                showTooltip={showTooltip}
                clickable
                variant="light"
              >
                <div className="flex max-h-314 flex-row flex-wrap gap-2 overflow-y-auto p-3">
                  {restOfTheChipList.map((item) => (
                    <>
                      <Chip
                        label={item.label}
                        key={item.value}
                        onRemove={(e) => handleChipRemove(e, item)}
                        className={tooltipChipClassName || chipClassName || ''}
                        isCloseIconRequired={isCloseIconRequired}
                        tooltipId={`PlusMore-${item.value}`}
                      />
                      <CustomTooltip
                        id={`PlusMore-${item.value}`}
                        className="shadow-tooltipShadow custom-scroll-bar z-20 max-w-500 mobile:max-w-300"
                        place="bottom"
                        variant="dark"
                        noArrow={false}
                      />
                    </>
                  ))}
                </div>
              </CustomTooltip>
            </div>
          )}
    </div>
  );
};

export default PlusNChipList;
