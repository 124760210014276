import React from 'react';

import { MultiValueDropDownLabelProps, TopicColor } from '@types';
import { topicColorMapping } from '@constants/dropdown';

import Chip from '../chip/Chip';

const MultiValueDropDownLabel = ({
  label,
  subLabel,
  className = ''
}: MultiValueDropDownLabelProps) => {
  const generateNumberFromString = (input: string): number => {
    const hashValue = Array.from(input).reduce(
      (acc, char) => acc + char.charCodeAt(0),
      0
    );
    const number = (hashValue % topicColorMapping.length) - 1 + 1;
    return number;
  };

  const getTopicColor = (input: string): TopicColor => {
    const number = generateNumberFromString(input);
    return topicColorMapping[number];
  };
  const theme = getTopicColor(label);

  return (
    <p className="flex items-center gap-3">
      <Chip
        label={label}
        className={`${theme.textColor} ${theme.backgroundColor} ${className}`}
      />
      <span>{subLabel}</span>
    </p>
  );
};

export default MultiValueDropDownLabel;
