import { HomeworkContainerVariants } from '@features/classes/constants';
import { DropDownItem } from './homework';
import { DateRange } from './date-range-picker';

// eslint-disable-next-line import/prefer-default-export
export enum ViewType {
  GRID = 'GRID',
  LIST = 'LIST'
}

export interface SubjectDropDownOption {
  id: string;
  label: string;
  value: string;
  subjects: DropDownItem;
}

export interface ClassHomeWorksProps {
  classId: number | string;
  type?: HomeworkContainerVariants;
  studentId?: string | number;
  studentName?: string;
  isSearchBarRequired?: boolean;
  level?: string | number;
}

export interface ClassCardProps {
  handleClick: (data: any) => void;
  data: any;
}

export type StudentPerfomance = {
  studentUserId: string;
  marksInPercentage: string;
  name: string;
  avatarUrl: string | null;
  completionTimeInMinutes?: number;
  points: number;
  pointsInPercentage: string;
};

export interface StudentBoardProps {
  title: string;
  iconBackgroundColor: string;
  studentList: StudentPerfomance[];
  isLoading?: boolean;
  showCompletionTime?: boolean;
}

export interface ClassReportComponentProps {
  date: { startDate: Date; endDate: Date };
  classId: string;
  className?: string;
  setDataforRecommendation?: (data: any) => void;
}
export interface DateRangeDropdownItemType {
  label: string;
  startDate: Date;
  endDate: Date;
}
export interface DateRangeDropdownProps {
  label?: string;
  dateRanges: DateRangeDropdownItemType[];
  selectedDateRange: DateRangeDropdownItemType;
  onSelectDateRange: (dateRange: DateRangeDropdownItemType) => void;
  className?: string;
}

export interface DateRangePickerDropdownProps {
  onSelectDateRange: (dateRange: DateRange) => void;
  className?: string;
  selectedDateRange: DateRangeDropdownItemType;
}

export interface RewardProps {
  rewardPoint: string;
  rewardResponse: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  handlePointClick: (item: string) => void;
  handleRewardResponse: (item: string) => void;
}

export interface DeleteRewardProps {
  handleCancel: () => void;
  handleConfirm: () => void;
  rewardPoint: string;
  student: string;
}

export interface RewardHistoryProps {
  handleCancel: () => void;
  academicPointId: string;
}
