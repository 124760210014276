import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LineChartProps } from '@types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// Custom CSS for the tooltip
const styles = `
.custom-tooltip {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 5px 10px;
  pointer-events: none;
  display: none;
  white-space: nowrap;
}
`;

const LineChart: React.FC<LineChartProps> = ({
  labels,
  primaryValue,
  secondaryValue,
  primaryColor,
  secondaryColor
}) => {
  const chartRef = useRef<ChartJS<'line'>>(null);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;

    const { canvas } = chartInstance;
    const tooltipEl = document.createElement('div');
    tooltipEl.className = 'custom-tooltip';
    document.body.appendChild(tooltipEl);

    const handleMouseMove = (event: MouseEvent) => {
      const x = event.clientX;
      const y = event.clientY;
      const canvasPosition = canvas.getBoundingClientRect();
      const relativeX = x - canvasPosition.left;
      const relativeY = y - canvasPosition.top;

      const xScale = chartInstance.scales.x;
      const yScale = chartInstance.scales.y;

      const closestLabelIndex = xScale.getValueForPixel(relativeX);

      if (
        relativeY > yScale.bottom &&
        closestLabelIndex !== null &&
        Number.isFinite(closestLabelIndex)
      ) {
        const labelIndex = closestLabelIndex as number;
        const label = labels[labelIndex];
        const characterLimit = 10;

        if (label.length >= characterLimit) {
          tooltipEl.innerHTML = label;
          // Initial tooltip position
          let tooltipX = x;
          const tooltipY = y;

          // Calculate available space on the right
          const tooltipWidth = tooltipEl.offsetWidth;
          const viewportWidth = window.innerWidth;
          const rightSpace = viewportWidth - x;

          // Adjust tooltip position if it's getting cropped
          if (tooltipWidth > rightSpace) {
            tooltipX -= tooltipWidth; // Move tooltip to the left
          }

          tooltipEl.style.left = `${tooltipX}px`;
          tooltipEl.style.top = `${tooltipY}px`;
          tooltipEl.style.display = 'block';
        } else {
          tooltipEl.style.display = 'none';
        }
      } else {
        tooltipEl.style.display = 'none';
      }
    };

    const handleMouseLeave = () => {
      tooltipEl.style.display = 'none';
    };

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseleave', handleMouseLeave);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
      document.body.removeChild(tooltipEl);
    };
  }, [labels]);

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          callback(value: string | number, index: number): string {
            const label = labels[index];
            const characterLimit = 10;
            if (label.length >= characterLimit) {
              return `${label.slice(0, characterLimit - 1).trim()}...`;
            }
            return label;
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [
      {
        data: primaryValue,
        borderColor: primaryColor,
        borderWidth: 7
      },
      {
        data: secondaryValue,
        borderColor: secondaryColor,
        borderWidth: 7,
        borderDash: [15, 5]
      }
    ]
  };

  return <Line ref={chartRef} options={options} data={data} />;
};

export default LineChart;
