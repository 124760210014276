/* eslint-disable @typescript-eslint/no-unused-vars */
import { ViewType } from '@types';
import { ViewChangerProps } from 'types/view-changer';

import Icon from '../icon/Icon';

const ViewChanger = ({ view, onChange }: ViewChangerProps) => (
  <div className="flex cursor-pointer items-center justify-between gap-1 rounded-lg bg-primary1 p-2">
    <Icon
      name={view === ViewType.GRID ? 'grid-select' : 'grid-unselect'}
      onClick={() => onChange(ViewType.GRID)}
      key={view === ViewType.GRID ? 'grid-select' : 'grid-unselect'}
      size="none"
    />
    <Icon
      name={view === ViewType.LIST ? 'list-select' : 'list-unselect'}
      onClick={() => onChange(ViewType.LIST)}
      key={view === ViewType.LIST ? 'list-select' : 'list-unselect'}
      size="none"
    />
  </div>
);

export default ViewChanger;
