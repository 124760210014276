export enum TabType {
  CHIP = 'chip',
  DEFAULT = 'default'
}

export interface TabPill {
  id: number;
  label: string;
  count?: string | number;
  status?: string;
}

export interface TabProps {
  type?: TabType;
  tabs: TabPill[];
  onTabSelect?: (tab: TabPill) => void;
  selectedTab?: TabPill;
  className?: string;
}
