import { Tooltip } from 'react-tooltip';

import { CustomTooltipProps } from '@types';

const CustomTooltip = (props: CustomTooltipProps) => {
  const {
    id,
    type,
    className,
    place = 'top',
    opacity = 1,
    variant = 'dark',
    clickable = false,
    tooltipText,
    showTooltip,
    noArrow = true,
    children
  } = props;
  const renderTooltip = () => {
    const tooltipContent = type === 'text' ? tooltipText : children;

    return (
      <Tooltip
        id={id}
        noArrow={noArrow}
        className={`z-10 !rounded-lg !text-sm !font-normal ${className || ''}`}
        opacity={opacity}
        place={place}
        variant={variant}
        clickable={clickable}
        isOpen={showTooltip}
      >
        {tooltipContent}
      </Tooltip>
    );
  };

  return renderTooltip();
};

export default CustomTooltip;
