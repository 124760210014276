import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorCard } from '@components';
import backgroundImage from '@assets/images/error_page_background.svg';
import {
  errorScreenData,
  SOMETHING_WENT_WRONG
} from '@features/error/constants';
import { Event, sendMixpanelEvent } from '@core/analytics';

const ErrorPage = () => {
  const { errorStatus = SOMETHING_WENT_WRONG } = useParams();
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  const handleHomeButtonClick = () => {
    navigate('/');
  };

  useEffect(() => {
    sendMixpanelEvent(Event.APP_FAILURE_OR_CRASH, {
      Category: 'App Issues',
      Label: errorStatus
    });
  }, []);

  return (
    <div
      className="flex h-screen w-screen items-center justify-center"
      style={backgroundStyle}
    >
      <ErrorCard
        handleHomeButtonClick={handleHomeButtonClick}
        {...errorScreenData[errorStatus as keyof typeof errorScreenData]}
      />
    </div>
  );
};

export default ErrorPage;
