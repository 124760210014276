import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      { payload: { user } }: PayloadAction<{ user: any }>
    ) => ({
      ...state,
      currentUser: user
    }),
    resetUserState: () => initialState
  }
});

export const { setCurrentUser, resetUserState } = userSlice.actions;
export default userSlice.reducer;
