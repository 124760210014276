import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { STUDENTS } from '@constants/routes';

const StudentsList = lazy(
  () => import('@features/students/pages/students/Students')
);
const StudentDetail = lazy(
  () => import('@features/students/pages/student-details/StudentDetails')
);

const HomeworkDetails = lazy(
  () => import('@features/students/pages/homework-details/HomeworkDetails')
);

const Reports = lazy(() => import('@features/students/pages/reports/Reports'));

export const StudentsRoutes: RouteObject[] = [
  {
    path: STUDENTS.STUDENTS,
    element: <StudentsList />
  },
  {
    path: STUDENTS.STUDENT,
    element: <StudentDetail />
  },
  {
    path: STUDENTS.STUDENT_HOMEWORK_DETAILS,
    element: <HomeworkDetails />
  },
  {
    path: STUDENTS.STUDENT_REPORTS,
    element: <Reports />
  }
];

export default StudentsRoutes;
