/* eslint-disable import/prefer-default-export */
import { TopicColor } from '@types';

// TODO need to change color to constants
export const topicColorMapping: TopicColor[] = [
  { backgroundColor: 'bg-[#E7F6EC]', textColor: '!text-[#04802E]' },
  { backgroundColor: 'bg-[#FEF6E7]', textColor: '!text-[#AD6F07]' },
  { backgroundColor: 'bg-[#FFF9F1]', textColor: '!text-[#FFC571]' },
  { backgroundColor: 'bg-[#F2F9F0]', textColor: '!text-[#7EC665]' },
  { backgroundColor: 'bg-[#FFF7F6]', textColor: '!text-[#927AFF]' },
  { backgroundColor: 'bg-[#CAE6FE]', textColor: '!text-[#4FABFD]' },
  { backgroundColor: 'bg-[#F4F2FF]', textColor: '!text-[#927AFF]' }
];
