export enum SourceAppType {
  EDUCATOR = 'Educator',
  STUDENT = 'Student'
}

export type LoginRequest = {
  email: String;
  sourceApp: SourceAppType;
};

export type ValidationError = {
  [key: string]: string;
};

export type ValidateOTPRequest = {
  email: String;
  otp: String;
  sourceApp: SourceAppType;
};
