import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { HOMEWORK_MANAGEMENT } from '@constants/routes';

const Homeworks = lazy(
  () => import('@features/homework-management/pages/homeworks/Homeworks')
);

const Preview = lazy(
  () => import('@features/homework-management/pages/preview/Preview')
);

const HomeworkDetails = lazy(
  () =>
    import(
      '@features/homework-management/pages/homework-details/HomeworkDetails'
    )
);

const Reports = lazy(
  () => import('@features/homework-management/pages/reports/Reports')
);

export const HomeworkManagementRoutes: RouteObject[] = [
  {
    path: HOMEWORK_MANAGEMENT.HOMEWORKS,
    element: <Homeworks />
  },
  {
    path: HOMEWORK_MANAGEMENT.PREVIEW,
    element: <Preview />
  },
  {
    path: HOMEWORK_MANAGEMENT.HOMEWORK_DETAILS,
    element: <HomeworkDetails />
  },
  {
    path: HOMEWORK_MANAGEMENT.REPORTS,
    element: <Reports />
  }
];

export default HomeworkManagementRoutes;
