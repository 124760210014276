import React, { FC, useEffect, useState } from 'react';
import {
  Range,
  RangeKeyDict,
  DateRangePicker as ReactDateRangePicker
} from 'react-date-range';
import clsx from 'clsx';

import { DateRangePickerProps } from '@types';
import Button from '@components/button/Button';
import { getDateRangeForToday } from '@utils/date';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './styles.css';
import colors from '../../../themes/colors';

const DateRangePicker: FC<DateRangePickerProps> = ({
  calendarStyle,
  selectedDateRange,
  cancelButtonStyle,
  cancelButtonLabel,
  onCancel,
  confirmButtonLabel,
  confirmButtonStyle,
  onConfirm,
  resetButtonLabel,
  resetButtonStyle,
  onReset
}) => {
  const [dateRanges, setDateRanges] = useState<Range[]>([
    { key: 'range', ...getDateRangeForToday() }
  ]);

  const handleDateRangeChange = (item: RangeKeyDict) => {
    setDateRanges([item.range]);
  };

  const handleReset = () => {
    setDateRanges([{ ...selectedDateRange, key: 'range' }]);
    if (onReset) {
      onReset();
    }
  };

  const handleConfirm = () => {
    onConfirm({
      startDate: dateRanges[0]?.startDate ?? selectedDateRange.startDate,
      endDate: dateRanges[0]?.endDate ?? selectedDateRange.endDate
    });
  };

  useEffect(() => {
    if (selectedDateRange)
      setDateRanges([{ ...selectedDateRange, key: 'range' }]);
  }, [selectedDateRange]);

  return (
    <div className="date-range-picker w-full">
      <ReactDateRangePicker
        className={`${calendarStyle} w-full !bg-transparent !text-sm !text-content`}
        ranges={dateRanges}
        onChange={handleDateRangeChange}
        color={`${colors.primary}`}
        weekdayDisplayFormat="EEEEEE"
        weekStartsOn={1}
        editableDateInputs
        moveRangeOnFirstSelection={false}
      />
      <div className="flex items-center p-2">
        {resetButtonLabel && (
          <Button
            className={clsx('me-1', resetButtonStyle)}
            variant="transparent"
            size="extraSmall"
            labelStyle="text-sm"
            label={resetButtonLabel}
            handleClick={handleReset}
          />
        )}
        <Button
          className={clsx('me-1 ms-auto', cancelButtonStyle)}
          variant="secondary"
          size="extraSmall"
          labelStyle="text-sm"
          label={cancelButtonLabel}
          handleClick={onCancel}
        />
        <Button
          className={confirmButtonStyle}
          size="extraSmall"
          labelStyle="text-sm"
          label={confirmButtonLabel}
          handleClick={handleConfirm}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
