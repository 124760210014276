import { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';

import { Button } from '@components';
import { DatePickerProps } from '@types';
import useOnClickOutside from '@hooks/clickOutside';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

import colors from '../../../themes/colors';

const DatePicker = ({
  className,
  title = '',
  handleCancel,
  cancelButtonStyle,
  cancelButtonLabel,
  handleConfirm,
  confirmButtonStyle,
  confirmButtonLabel,
  selectedDate,
  calendarStyle,
  isButtonDriven = false
}: DatePickerProps) => {
  const ref = useRef(null);
  const [date, setDate] = useState<Date>();

  useOnClickOutside(ref, () => {
    handleCancel();
  });

  useEffect(() => {
    if (selectedDate) setDate(selectedDate);
  }, [selectedDate]);

  return (
    <div
      className={`date-picker flex flex-col items-center justify-center rounded-md border border-grey40 text-sm font-normal ${
        className || ''
      }`}
      ref={ref}
    >
      <div className="mb-2 w-full text-center text-base">{title}</div>

      <Calendar
        className={`${calendarStyle} !bg-transparent !text-sm !text-content`}
        date={date}
        minDate={new Date()}
        onChange={(modifiedDate: any) => {
          setDate(modifiedDate);
          if (!isButtonDriven) handleConfirm(modifiedDate);
        }}
        color={`${colors.primary}`}
        showMonthAndYearPickers={false}
      />
      {isButtonDriven && (
        <div className="my-2 flex w-full justify-between">
          <Button
            handleClick={handleCancel}
            variant="transparent"
            size="none"
            className={`ml-4 text-base font-medium ${
              cancelButtonStyle && cancelButtonStyle
            }`}
          >
            {cancelButtonLabel}
          </Button>
          <Button
            disabled={!date}
            handleClick={() => handleConfirm(date)}
            variant="transparent"
            size="none"
            className={`mr-4 text-base font-medium ${
              confirmButtonStyle && confirmButtonStyle
            }`}
          >
            {confirmButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
