import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { NOTIFICATIONS } from '@constants/routes';

const Notifications = lazy(
  () => import('@features/notification/pages/notifications/Notifications')
);

export const NotificationRoutes: RouteObject[] = [
  {
    path: NOTIFICATIONS.NOTIFICATIONS,
    element: <Notifications />
  }
];

export default NotificationRoutes;
