import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { CLASSES } from '@constants/routes';

const ClassList = lazy(
  () => import('@features/classes/pages/class-list/Classes')
);

const Class = lazy(() => import('@features/classes/pages/class/Class'));

const Reports = lazy(() => import('@features/classes/pages/reports/Reports'));

export const ClassesRoutes: RouteObject[] = [
  {
    path: CLASSES.CLASSES,
    element: <ClassList />
  },
  {
    path: CLASSES.CLASS,
    element: <Class />
  },
  {
    path: CLASSES.REPORTS,
    element: <Reports />
  }
];

export default ClassesRoutes;
