import { END_POINTS } from '@config';
import { api } from '@core/baseApi';
import { HTTPResponse, LoginRequest, ValidateOTPRequest } from '@types';

const extendedBaseQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<HTTPResponse<any>, LoginRequest>({
      query: (credentials) => ({
        url: END_POINTS.AUTH.SEND_OTP,
        method: 'POST',
        body: credentials
      })
    }),
    validateOtp: builder.mutation<HTTPResponse<any>, ValidateOTPRequest>({
      query: (credentials) => ({
        url: END_POINTS.AUTH.VERIFY_OTP,
        method: 'POST',
        body: credentials
      })
    }),
    getUserDetails: builder.query<HTTPResponse<any>, void>({
      query: () => ({
        url: END_POINTS.AUTH.USER,
        method: 'GET'
      })
    })
  })
});

export const {
  useLoginMutation,
  useValidateOtpMutation,
  useGetUserDetailsQuery
} = extendedBaseQuery;
