export const API_URL = process.env.REACT_APP_API_URL;
export const END_POINTS = {
  AUTH: {
    SEND_OTP: '/api/v1/auth/send-otp',
    VERIFY_OTP: '/api/v1/auth/verify-otp',
    REFRESH: '/api/v1/auth/refresh-token',
    USER: '/api/v1/user/me'
  },
  HOMEWORKS: {
    SUBJECT_LEVEL: '/api/v1/teacher/subject-level',
    SKILL_WITH_TOPIC: '/api/v1/skill',
    CREATE_NEW_ASSIGNMENT: '/api/v1/assignment',
    QUESTION_RECOMMENDATION: '/api/v1/assignment/question-recommendation',
    CLASSES_FOR_TEACHER: '/api/v1/teacher/classes',
    CLASSES_FOR_TEACHER_DROPDOWN: '/api/v1/teacher/classes-drop-down-list',
    QUESTION_DETAILS: '/api/v1/question',
    QUESTION_MAPPING: '/api/v1/assignment/question-mappings',
    SHARE: '/api/v1/assignment/share',
    REPLACE_QUESTION: '/api/v1/assignment/replace-question-recommendation',
    ASSIGNMENT_CHANGE_QUESTION_RECOMMENDATION:
      '/api/v1/assignment/change-question-recommendation',
    ASSIGNMENT_LIST: '/api/v1/assignment/shared',
    SUMMARY: '/api/v1/assignment/summary',
    STUDENTS_SUMMARY: (assignmentID: string) =>
      `/api/v1/assignment/${assignmentID}/students`,
    ASSIGNMENT_SUMMARY: (assignmentID: string) =>
      `/api/v1/assignment/${assignmentID}/summary`,
    ASSIGNMENT_DETAILS: (assignmentID: string) =>
      `/api/v1/assignment/${assignmentID}`,
    STUDENT_HOMEWORK_SUMMARY: (assignmentId: string, studentId: string) =>
      `/api/v1/assignment/${assignmentId}/student/${studentId}/summary`,
    STUDENT_RESPONSE: (
      assignmentId: string,
      studentId: string,
      questionId: string
    ) =>
      `/api/v1/assignment/${assignmentId}/student/${studentId}/question/${questionId}`,
    REPORTS: (assignmentId: string) =>
      `/api/v1/assignment/reports/${assignmentId}`,
    STUDENT_REVIEW: (
      assignmentId: string,
      studentId: string,
      questionId: string
    ) =>
      `/api/v1/assignment/${assignmentId}/student/${studentId}/question/${questionId}`,
    STUDENT_OVERALL_PERFOMANCE: (assignmentId: string, studentId: string) =>
      `/api/v1/assignment/${assignmentId}/student/${studentId}/overall-performance`,
    STUDENT_FEEDBACK_LIST: (assignmentShareId: string) =>
      `/api/v1/assignment/shareId/${assignmentShareId}/students-summary`,
    STUDENT_FEEDBACK_UPDATE_AND_PUBLISH: (studentHomeworkSectionId: string) =>
      `/api/v1/assignment/studentHomeworkSection/${studentHomeworkSectionId}/feedback`,
    STUDENT_FEEDBACK_UNPUBLISH: `/api/v1/assignment/unpublish-student-summary`,
    STUDENT_FEEDBACK_PUBLISH: '/api/v1/assignment/publish-student-summary'
  },
  CLASSES: {
    STUDENTS_LIST: (classId: string) => `/api/v1/class/${classId}/students`,
    ASSIGNMENT_LIST: '/api/v1/assignment/shared',
    TOPIC_REPORT: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/topic-report`,
    COMMEON_ERRORS: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/common-errors`,
    QUESTION_TYPE_PERFOMANCE: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/question-type-performance`,
    OVERALL_PERFOMANCE: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/overall-performance`,
    STUDENT_PERFOMANCE: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/student-performance`,
    CLASS_RECOMMENDATION: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/recommendation`,
    CLASS_AVERAGE_SCORE: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/average-score`,
    CLASS_TOTAL_HOMEWORK_COUNT: (classId: string) =>
      `/api/v1/assignment-report/class/${classId}/total-homework-count`,
    LEADERBOARD: '/api/v1/gamification/teacher/leaderboard',
    REWARD_POINTS: '/api/v1/gamification/bonus-point',
    REWARD_HISTORY: '/api/v1/gamification/bonus-point-history',
    DELETE_REWARD_POINT: (bonusPointId: string) =>
      `/api/v1/gamification/bonus-point/${bonusPointId}`
  },
  STUDENTS: {
    STUDENTS_LIST: '/api/v1/class/students',
    STUDENT_DETAILS: (studentId: string) => `/api/v1/student/${studentId}`,
    STUDENT_HOMEWORKS: (studentId: string | number) =>
      `/api/v1/assignment/students/${studentId}`,
    STUDENT_SKILL_REPORT: (studentId: string) =>
      `/api/v1/assignment/skills-report/student/${studentId}`,
    STUDENT_SCORE_TREND_OVER_TIME: (studentId: string) =>
      `/api/v1/assignment-report/student/${studentId}/homework-score-trend`,
    STUDENT_REPORT_HOMEWORK_SCORE_LIST: (studentId: string) =>
      `api/v1/assignment-report/student/${studentId}/homework-score-list`,
    STUDENT_AVG_SCORE_TIME: (studentId: string) =>
      `/api/v1/assignment-report/student/${studentId}/average-score-time`,
    STUDENT_FEEDBACK_RECOMMENDATION: (studentId: string) =>
      `/api/v1/assignment-report/student/${studentId}/feedback-recommendation`,
    STUDENT_HOMEWORK_COMPLETION_RATE: (studentId: string) =>
      `/api/v1/assignment-report/student/${studentId}/completion-rate`
  },
  SETTINGS: {
    SUBMIT_FEEDBACK: '/api/v1/settings/feedback',
    GET_FILE_UPLOAD_LINK: '/api/v1/file/upload-url',
    CONTACT_SUPPORT: '/api/v1/settings/support-request',
    DELETE_ACCOUNT: '/api/v1/user/delete',
    GET_FAQ_LIST: '/api/v1/settings/faq',
    USER_SUPPORT_REQUEST: '/api/v1/settings/support-request/me'
  }
};
