import {
  CLASSES,
  ERROR,
  HOMEWORK_MANAGEMENT,
  NOTIFICATIONS,
  SETTINGS,
  STUDENTS
} from '../../constants/routes';

enum Event {
  // Login Page events
  LOGIN_OTP_SUCCESS = 'Login otp number success',
  LOGIN_OTP_ERROR = 'Login otp number error',
  LOGIN_CONTACT_SUPPORT = 'Login contact support issue',

  // Homework Events
  HOMEWORK_CREATION = 'Homework Created',
  HOMEWORK_DISTRIBUTION = 'Homework Assigned',

  // Class Events
  CLASS_STUDENT_LIST = 'View Student List',

  // AI
  AI_HOMEWORK_SUMMARY = 'View AI Homework Summary',
  AI_STUDENT_SUMMARY = 'View AI Student Summary',

  // Student events
  BONUS_POINTS = 'Add Bonus Points',

  // Delete Account
  DELETE_ACCOUNT = 'Delete Account',

  // Error
  APP_FAILURE_OR_CRASH = 'App failure or crash issue'
}

export default Event;

export const PageNames = {
  [HOMEWORK_MANAGEMENT.HOMEWORKS]: 'Homeworks',
  [HOMEWORK_MANAGEMENT.HOMEWORK_DETAILS]: 'Homework Details',
  [HOMEWORK_MANAGEMENT.PREVIEW]: 'Preview',
  [HOMEWORK_MANAGEMENT.REPORTS]: 'Homework Reports',

  [CLASSES.CLASSES]: 'Class List',
  [CLASSES.REPORTS]: 'Class Report',

  [STUDENTS.STUDENTS]: 'Student List',
  [STUDENTS.STUDENT_REPORTS]: 'Student Report',

  [NOTIFICATIONS.NOTIFICATIONS]: 'Notifications',

  [SETTINGS.SETTINGS]: 'Settings',

  [ERROR.ERROR]: 'Error Page'
};

export const pathList = Object.keys(PageNames).map((item) => ({
  path: item
}));
