export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export const HTTPStatus = {
  '401': '401',
  '404': '404',
  '500': '500',
  '503': '503'
};
