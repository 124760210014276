import { FC, useState } from 'react';
import clsx from 'clsx';

import { AccordionProps } from '@types';

const Accordion: FC<AccordionProps> = ({ className, trigger, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpenState = () => setOpen((prevState) => !prevState);

  return (
    <div className={className}>
      <div onClick={toggleOpenState} role="presentation">
        {trigger(open)}
      </div>
      <div
        className={clsx(
          'grid overflow-hidden duration-200',
          open ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
        )}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
