import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BarChartProps, BarChartType } from '@types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = ({
  type = BarChartType.BASIC,
  labels,
  primaryValue,
  secondaryValue,
  backgroundColor,
  showDataLabel = false,
  isHorizontal = false,
  barThickness,
  dataLabelFontType = 'desktop'
}: BarChartProps) => {
  const dataLabelFontSize = {
    mobile: 14,
    desktop: 25
  };

  const options = {
    indexAxis: isHorizontal ? ('y' as const) : ('x' as const),
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: showDataLabel,
        anchor: 'start' as const,
        align: 'top' as const,
        textAlign: 'center' as const,
        font: {
          size: dataLabelFontSize[dataLabelFontType],
          weight: 800
        },
        labels: {
          value: {
            color: 'black'
          }
        },
        formatter(val: any) {
          return `${val}%`;
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        color: 'black',
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        padding: 10,
        borderWidth: 0.5,
        borderColor: 'black',
        callbacks: {
          label(context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (isHorizontal) {
              if (context.parsed.x !== null) {
                label += `${context.parsed.x}`;
              }
            } else if (context.parsed.y !== null) {
              label += `${context.parsed.y}`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: !!BarChartType.STACKED,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: !!BarChartType.STACKED,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    }
  };

  const basicData = {
    labels,
    datasets: [
      {
        data: primaryValue,
        backgroundColor,
        borderSkipped: false,
        borderRadius: 16,
        barThickness
      }
    ]
  };

  const stackData = {
    labels,
    datasets: [
      {
        data: primaryValue,
        backgroundColor: backgroundColor[0],
        borderRadius: 16,
        barThickness
      },
      {
        data: secondaryValue,
        backgroundColor: backgroundColor[1],
        borderRadius: 16,
        barThickness
      }
    ]
  };

  return (
    <>
      {type === BarChartType.BASIC && (
        <Bar options={options} data={basicData} />
      )}
      {type === BarChartType.STACKED && (
        <Bar options={options} data={stackData} />
      )}
    </>
  );
};

export default BarChart;
